import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField,
  FunctionField,
  SelectInput,
  Filter,
  TextInput,
  BulkExportButton,
  Pagination,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport";

const ApplicationFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="status"
      emptyText="All"
      choices={[
        { id: "REFERRED", name: "Referred" },
        { id: "ACCEPTED", name: "Accepted" },
        { id: "APPROVED", name: "Approved" },
        { id: "ACCEPTED", name: "Accepted" },
        { id: "DECLINED", name: "Declined" },
      ]}
      alwaysOn
    />
    <SelectInput
      label="AutoApprove"
      source="auto_approve"
      emptyText="All"
      choices={[
        { id: "APPROVED", name: "Approved" },
        { id: "LOW_RISK", name: "Low risk"},
        { id: "APPROVED_CAPPED", name: "Capped" },
        { id: "REFERRED", name: "Referred" },
        { id: "REJECTED", name: "Rejected" },
      ]}
      alwaysOn
    />
    <SelectInput
      label="RiskSegment"
      source="risk_segment"
      emptyText="All"
      choices={[
        { id: "Superprime", name: "Superprime" },
        { id: "Prime+", name: "Prime+" },
        { id: "Prime", name: "Prime" },
        { id: "Nearprime+", name: "Nearprime+" },
        { id: "Nearprime", name: "Nearprime" },
        { id: "Nearprime-", name: "Nearprime-" },
        { id: "Subprime+", name: "Subprime+" },
        { id: "Subprime", name: "Subprime" },
      ]}
      alwaysOn
    />
    <TextInput label="User" type="email" source="search" alwaysOn />
    <TextInput label="Campaign" source="campaign" />
  </Filter>
);

const ApplicationsBulkActionButtons = (props: any) => (
  <>
    <BulkExportButton {...props} />
  </>
);

const MidPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const exporter = (records: any) => {
  const data = records.map((record: any) => {
    const { repayment_schedules, ...filteredRecord } = record;
    return filteredRecord;
  });
  jsonExport(
    data,
    {
      headers: Object.keys(data[0]), // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "applications"); // download as 'posts.csv` file
    },
  );
};

type Props = {};

const ApplicationList = (props: Props) => {
  return (
    <List
      {...props}
      filters={<ApplicationFilter />}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      pagination={<MidPagination />}
      exporter={exporter}
    >
      <Datagrid rowClick="show">
        <DateField source="created_at" />
        <ReferenceField
          source="user"
          reference="loans/adminui/user"
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="profile"
          reference="loans/adminui/profile"
          link="show"
        >
          <FunctionField
            label="Name"
            render={(record: any) =>
              `${record.title} ${record.first_name} ${record.family_name}`
            }
          />
        </ReferenceField>
        <DateField source="updated_at" />
        <TextField source="status" />
        <TextField source="auto_approve" />
        <NumberField source="max_amount" />
        <FunctionField
          label="interestRate"
          render={(record: any) =>
            `${Math.round(record.interestRate * 1000) / 10}%`
          }
        />
        <TextField source="reason" />
        <TextField source="max_duration" />
        <NumberField source="amount" />
        <TextField source="finished_at" />
      </Datagrid>
    </List>
  );
};

export default ApplicationList;
