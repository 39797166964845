import * as React from "react";
import {
  Show,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  FunctionField,
  UrlField,
  Tab,
  Datagrid,
  ArrayField,
  useQueryWithStore,
  useShowController,
  ListContext,
} from "react-admin";
import parseISO from "date-fns/parseISO";
import parse from "date-fns/parse";
import differenceInMonths from "date-fns/differenceInMonths";
import { Chip } from "@material-ui/core";
import {
  Calculator,
  Mode,
  SeriesAdvance,
  SeriesPayment,
  EU200848EC,
} from "@curo/calculator";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ClipboardListField } from "react-admin-clipboard-list-field";
import AccountTypeMappings from "./AccountTypeMappings";
import AccountStatusMappings from "./AccountStatusMappings";
import ApproveButton from "./ApproveButton";
import UpdateButton from "./UpdateButton";

import "../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  DiscreteColorLegend,
  LineSeries,
} from "react-vis";
import useStyles from "./Styles";
import { TopToolbar } from "react-admin";

type Props = {
  record: any;
};

function getCosmosDataFromCreditDecision(decision: any) {
  // first look on the workflow output
  if (decision.WorkflowOutputJson != null) {
    const workflowOutput = JSON.parse(decision.WorkflowOutputJson);
    if (workflowOutput.Cosmos != null) {
      return workflowOutput.Cosmos;
    }
  }

  // now start looking on the data source states.
  let cosmos;
  const q0 = decision.DataSourceStates.filter(
    (r: any) => r.Name === "COSMOS_ADDRESS_2",
  );
  if (q0.length > 0) {
    cosmos = q0[0];
  } else {
    const q1 = decision.DataSourceStates.filter(
      (r: any) => r.Name === "COSMOS_ADDRESS_1",
    );
    if (q1.length > 0) {
      cosmos = q1[0];
    } else {
      const q2 = decision.DataSourceStates.filter(
        (r: any) => r.Name === "Cosmos",
      );
      if (q2.length > 0) {
        cosmos = q2[0];
      }
    }
  }

  return cosmos.OutputJson ? JSON.parse(cosmos.OutputJson) : {};
}

const ApplicationActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <UpdateButton application={data} />
      <ApproveButton application={data} />
    </TopToolbar>
  );
};

// https://marmelab.com/react-admin/List.html#row-style-function
const creditReportRowStyle = (record: any) => ({
  backgroundColor: record.default ? "rgba(224,85,97, 0.3)" : "white",
});

const ApplicationShow = (props: Props) => {
  const classes = useStyles();
  const [accountTypes, setAccountTypes] = React.useState<
    { title: string; selected: boolean }[]
  >([]);

  const group1 = [
    "RiskSegment",
    "idmatch",
    "verifyp3m",
    "gaugeScore",
    "gaugeScoreSTL",
    "CreditFile",
  ];
  const group6 = ["BCCfull", "BCCjoint", "BCClimit", "unsecured", "DI", "SR"];
  const group2 = [
    "BureauCreditCommitment",
    "DebtxMortgage",
    "Debt",
    "InstalmentsBal",
    "InstalmentsCount",
    "DebtIncome",
    "instalments",
    "instalmentswithmortgage",
    "mortgagepayment",
    "cardpayments",
  ];
  const group3 = [
    "TotalAccounts",
    "ActiveAccounts",
    "RevolvingCreditBal",
    "RevolvingCreditLimits",
    "RevolvingCreditCount",
    "RevolvingCreditActive",
    "RevolvingCreditActive_75pctplus",
    "RevolvingCreditActive_100pctplus",
    "RevolvingRatio75",
    "RevolvingRatio100",
    "Utilisation",
  ];
  const group4 = [
    "BankOverdraftBal",
    "BankOverdraftCount",
    "HomeCreditBal",
    "HomeCreditCount",
    "MortgageBal",
    "MortgageCount",
    "NewAccountp3m",
    "NewAccountp3mValue",
    "NewAccountp6m",
    "BK_IVA_CCJGT500",
    "UnsatisfiedCCJ",
    "BK_IVA",
    "MonthsSince1plus",
    "MonthsSince1plus_exHistoricDF",
    "MonthsSince2plus_XH",
    "MonthsSince3plus_XH",
    "DebtCollectionSearch",
    "Deceased",
    "ActivePayday",
    "HistoricDefaultBalance",
    "TimeSinceDefault",
    "DebtManagementBalances_p12m",
    "GoneAway",
  ];

  const group5 = [
    "LJC",
    "DB",
    "AB",
    "WN",
    "BCC",
    "CCC",
    "DCC",
    "NEB",
    "LQB",
    "ZHC",
    "RR",
    "TR",
  ];

  const offer_group1 = [
    "CreditFile",
    "DISegment",
    "ProductSegment",
    "Segment",
    "RiskSegment",
  ];
  const affordability_group = [
    "creditCommittment",
    "housingCost",
    "bills",
    "otherExpense",
  ];
  const intlFormatter = new Intl.NumberFormat();
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  let salary = null;
  ({ data: salary } = useQueryWithStore({
    type: "getOne",
    resource: "loans/decision/salary",
    payload: { id: record ? record.user_id : undefined },
  }));
  let salaryForCalc = salary;
  if (salary) {
    salaryForCalc = salary.salary;
  }
  let profile = null;
  ({ data: profile } = useQueryWithStore({
    type: "getOne",
    resource: "loans/adminui/profile",
    payload: { id: record ? record.profile : undefined },
  }));
  if (profile && !salaryForCalc) {
    salaryForCalc = profile.net_income / 12;
  }
  let aff: any = null;
  ({ data: aff } = useQueryWithStore({
    type: "getOne",
    resource: "loans/adminui/affordability",
    payload: { id: profile ? profile.affordability : undefined },
  }));
  let credit_decision: any = null;
  ({ data: credit_decision } = useQueryWithStore({
    type: "getOne",
    resource: "loans/adminui/decision",
    payload: { id: record ? record.credit_decision : undefined },
  }));
  let offer_review: any = null;
  ({ data: offer_review } = useQueryWithStore({
    type: "getOne",
    resource: "loans/adminui/decision",
    payload: { id: record ? record.offer_review : undefined },
  }));
  const updatedNDI =
    salaryForCalc -
    aff?.bills -
    aff?.housingCost -
    aff?.otherExpense -
    credit_decision?.raw_response?.WorkflowOutput?.BureauCreditCommitment;
  const totalOutgoing =
    aff?.bills +
    aff?.housingCost +
    aff?.otherExpense +
    (credit_decision?.raw_response?.WorkflowOutput?.cardpayments
      ? credit_decision?.raw_response?.WorkflowOutput?.cardpayments
      : 0) +
    (credit_decision?.raw_response?.WorkflowOutput?.regularpayments
      ? credit_decision?.raw_response?.WorkflowOutput?.regularpayments
      : 0);
  const customerExpense = aff?.bills + aff?.otherExpense;
  const affordabilityNet =
    offer_review?.raw_response?.WorkflowOutput?.underwritingIncome -
    totalOutgoing;
  return (
    <Show actions={<ApplicationActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="profile">
          <ReferenceField
            label="Title"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.startFormGroup}
          >
            <TextField source="title" />
          </ReferenceField>
          <ReferenceField
            label="First Name"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="first_name" />
          </ReferenceField>
          <ReferenceField
            label="Surname"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="family_name" />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Bank provided full names"
            source="user_id"
            reference="transactions/underwriting/accounts/names"
          >
            <FunctionField
              render={(record: any) => {
                if (record) {
                  return record.data.map((r: any) => (
                    <Chip
                      label={`${r.bank_profile_name}: ${r.full_name}`}
                      key={r.id}
                    />
                  ));
                }
                return null;
              }}
            />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Occupation"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="occupation" />
          </ReferenceField>
          <ReferenceField
            label="Employment Type"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="employment_status" />
          </ReferenceField>
          <ReferenceField
            label="Employer"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="employer_name" />
          </ReferenceField>
          <ReferenceField
            label="Months with Employer"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="employment_duration_min_months" />
          </ReferenceField>
          <ReferenceField
            label="Matched Employer"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <TextField source="profilematch.employer.name" />
          </ReferenceField>
          <ReferenceField
            label="Employer keywords"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <FunctionField
              render={(record: any) => {
                if (
                  record &&
                  record.profilematch &&
                  record.profilematch.employer
                ) {
                  return record.profilematch.employer.keyword_set.map(
                    (r: any) => <Chip label={r.keyword} key={r.id} />,
                  );
                }
                return null;
              }}
            />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Annual Salary"
            source="profile"
            reference="loans/adminui/profile"
            className={classes.nextFormGroup}
          >
            <NumberField source="customer_income" />
          </ReferenceField>
          <ReferenceField
            label="Change In Circumstance"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="change_in_circumstance" />
          </ReferenceField>
          <ReferenceField
            label="CIC Reason"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <TextField source="change_in_circumstance_reason" />
          </ReferenceField>
          <ReferenceField
            label="Expect Expenses Increase (EEI)"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="expect_expenses_increase" />
          </ReferenceField>
          <ReferenceField
            label="EEI Reason"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <TextField source="expect_expenses_increase_reason" />
          </ReferenceField>
          <ReferenceField
            label="Expect Change in Income (ECI)"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="expect_change_income" />
          </ReferenceField>
          <ReferenceField
            label="ECI Reason"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <TextField source="expect_change_income_reason" />
          </ReferenceField>
          <ReferenceField
            label="Other Income"
            source="profile"
            reference="loans/adminui/profile"
          >
            <NumberField source="other_income" />
          </ReferenceField>
          <ReferenceField
            label="age"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.age.value" />
          </ReferenceField>
          <TextField source="reason" />
          <ReferenceField
            label="Region"
            source="profile"
            reference="loans/adminui/profile"
          >
            <TextField source="address.region" />
          </ReferenceField>
          <ReferenceField
            label="Address Status"
            source="profile"
            reference="loans/adminui/profile"
          >
            <TextField source="address.status" />
          </ReferenceField>
          <ReferenceField
            label="Years Resident"
            source="profile"
            reference="loans/adminui/profile"
          >
            <TextField source="address.years_resident" />
          </ReferenceField>
          <ReferenceField
            label="Banks Connected"
            source="user_id"
            reference="transactions/adminui/connectedbanks"
          >
            <FunctionField
              render={(record: any) => {
                if (record) {
                  return record.data.map((r: any) => (
                    <Chip
                      label={r.bank_linked ? r.name : `${r.name} expired`}
                      key={r.id}
                    />
                  ));
                }
                return null;
              }}
            />
          </ReferenceField>
          <ReferenceField
            label="Cards Connected"
            source="user_id"
            reference="transactions/adminui/connectedcards"
          >
            <FunctionField
              render={(record: any) => {
                if (record) {
                  return record.data.map((r: any) => (
                    <Chip
                      label={r.bank_linked ? r.name : `${r.name} expired`}
                      key={r.id}
                    />
                  ));
                }
                return null;
              }}
            />
          </ReferenceField>
        </Tab>
        <Tab label="offer">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            label="Email for copy"
            source="user"
            reference="loans/adminui/user"
            link={false}
          >
            <ClipboardListField
              source="email"
              iconPosition="right"
              createRows={(value: string) => [value]}
            />
          </ReferenceField>
          <UrlField source="pipefy_url" />
          <TextField source="status" />
          <TextField source="reason" />
          <NumberField source="max_amount" />
          <FunctionField
            label="interestRate"
            render={(record: any) =>
              `${Math.round(record.interestRate * 1000) / 10}%`
            }
          />
          <TextField source="max_duration" />
          <ReferenceField
            label="Same User"
            source="id"
            reference="loans/adminui/applications"
          >
            <ArrayField source="self_applications">
              <Datagrid
                rowClick={(id: any, basePath: string, record: any) =>
                  `${basePath}/${record.id}/show`
                }
              >
                <DateField source="created_at" />
                <ReferenceField
                  source="profile"
                  reference="loans/adminui/profile"
                  link={false}
                >
                  <FunctionField
                    label="Name"
                    render={(record: any) =>
                      `${record.title} ${record.first_name} ${record.family_name}`
                    }
                  />
                </ReferenceField>
                <DateField source="updated_at" />
                <TextField source="status" />
                <NumberField source="max_amount" />
                <FunctionField
                  label="interestRate"
                  render={(record: any) =>
                    `${Math.round(record.interestRate * 1000) / 10}%`
                  }
                />
                <TextField source="reason" />
                <TextField source="max_duration" />
                <TextField source="finished_at" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
          <ReferenceField
            label="Same Email"
            source="id"
            reference="loans/adminui/applications"
          >
            <ArrayField source="other_applications">
              <Datagrid
                rowClick={(id: any, basePath: string, record: any) =>
                  `${basePath}/${record.id}/show`
                }
              >
                <DateField source="created_at" />
                <ReferenceField
                  source="profile"
                  reference="loans/adminui/profile"
                  link={false}
                >
                  <FunctionField
                    label="Name"
                    render={(record: any) =>
                      `${record.title} ${record.first_name} ${record.family_name}`
                    }
                  />
                </ReferenceField>
                <DateField source="updated_at" />
                <TextField source="status" />
                <NumberField source="max_amount" />
                <FunctionField
                  label="interestRate"
                  render={(record: any) =>
                    `${Math.round(record.interestRate * 1000) / 10}%`
                  }
                />
                <TextField source="reason" />
                <TextField source="max_duration" />
                <TextField source="finished_at" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </Tab>
        <Tab label="policy">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            label="Policy Decision Failures"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <FunctionField
              render={(record: any) => {
                if (record) {
                  return record.raw_response.Reasons.map((r: any) => (
                    <Chip label={r.Description} key={r.code} />
                  ));
                }
                return null;
              }}
            />
          </ReferenceField>
          <ReferenceField
            label="age"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.age.value" />
          </ReferenceField>
          <ReferenceField
            label="income"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.income.value" />
          </ReferenceField>
          <ReferenceField
            label="employment"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.employment.value" />
          </ReferenceField>
          <ReferenceField
            label="gambling"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.gambling.value" />
          </ReferenceField>
          <ReferenceField
            label="returnedDDCount"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.returnedDDCount" />
          </ReferenceField>
          <ReferenceField
            label="unemployment"
            source="policy_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.unemployment" />
          </ReferenceField>
          <ReferenceField
            label="Credit Policy Failures"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <FunctionField
              render={(record: any) => {
                if (record) {
                  return record.raw_response.Reasons.map((r: any) => (
                    <Chip label={r.Description} key={r.code} />
                  ));
                }
                return null;
              }}
            />
          </ReferenceField>
          <ReferenceField
            label="Current"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="is_current" />
          </ReferenceField>
          {group1.map((b) => (
            <ReferenceField
              label={b}
              source="credit_decision"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <TextField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
          <ReferenceField
            label="FileAge"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <TextField source="raw_response.WorkflowOutput.accountAge" />
          </ReferenceField>
          <div />
          {group6.map((b) => (
            <ReferenceField
              label={b}
              source="credit_decision"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <NumberField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
          <div />
          <ReferenceField
            label="cardpayments"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
            key="BCCfull"
          >
            <NumberField source="raw_response.WorkflowOutput.cardpayments" />
          </ReferenceField>
          <ReferenceField
            label="carfinancepayments"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.carfinancepayments" />
          </ReferenceField>
          <ReferenceField
            label="installmentloanpayments"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.installmentloanpayments" />
          </ReferenceField>
          <ReferenceField
            label="mortgagepayment"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.mortgagepayment" />
          </ReferenceField>{" "}
          <div />
          {group2.map((b) => (
            <ReferenceField
              label={b}
              source="credit_decision"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <NumberField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
          <div />
          {group3.map((b) => (
            <ReferenceField
              label={b}
              source="credit_decision"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <TextField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
          <div />
          {group4.map((b) => (
            <ReferenceField
              label={b}
              source="credit_decision"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <TextField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
          <div />
          {group5.map((b) => (
            <ReferenceField
              label={b}
              source="credit_decision"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <TextField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
        </Tab>
        <Tab label="affordability">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            label="Current"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
          >
            <BooleanField source="is_current" />
          </ReferenceField>
          <ReferenceField
            label="Offer Review Failures"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
          >
            <FunctionField
              render={(record: any) => {
                if (record) {
                  return record.raw_response.Reasons.map((r: any) => (
                    <Chip label={r.Description} key={r.code} />
                  ));
                }
                return null;
              }}
            />
          </ReferenceField>
          {offer_group1.map((b) => (
            <ReferenceField
              label={b}
              source="offer_review"
              reference="loans/adminui/decision"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <TextField source={`raw_response.WorkflowOutput.${b}`} />
            </ReferenceField>
          ))}
          <div />
          <ReferenceField
            label="Unsecured Debt"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.unsecured" />
          </ReferenceField>
          <ReferenceField
            label="Assignment Limit"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.AssignmentLimit" />
          </ReferenceField>
          <ReferenceField
            label="Final Limit"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.Limit" />
          </ReferenceField>
          <div />
          const offer_group2 = ["CustomerMonthlyIncome", "expenditure", "NDI"];
          <ReferenceField
            label="Underwriting Income"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingIncome" />
          </ReferenceField>
          <ReferenceField
            label="Underwriting Expenditure"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingExpenditure" />
          </ReferenceField>
          <ReferenceField
            label="Underwriting Housing Cost"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingHousingCost" />
          </ReferenceField>
          <ReferenceField
            label="BureauCreditCommitment"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingBCC" />
          </ReferenceField>
          <ReferenceField
            label="Underwriting NDI"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingNDI" />
          </ReferenceField>
          <ReferenceField
            label="Final NDI"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.NDI" />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Total Regular Income"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <FunctionField
              render={(record: any) =>
                intlFormatter.format(
                  (record.net_income + record.net_other_income) / 12,
                )
              }
            />
          </ReferenceField>
          <ReferenceField
            label="Total User Submitted"
            source="affordability"
            reference="loans/adminui/affordability"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="total" />
          </ReferenceField>
          {affordability_group.map((b) => (
            <ReferenceField
              label={b}
              source="affordability"
              reference="loans/adminui/affordability"
              link="show"
              className={classes.nextFormGroup}
              key={b}
            >
              <NumberField source={b} />
            </ReferenceField>
          ))}
          <FunctionField
            label="Customer Total Exp"
            render={() => intlFormatter.format(customerExpense)}
            className={classes.nextFormGroup}
          />
          <ReferenceField
            label="ONS Expenditure"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.ONSMaxExpenditure" />
          </ReferenceField>
          <ReferenceField
            label="Customer Declared NDI"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.CustomerDeclaredNDI" />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Affordability Income"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingIncome" />
          </ReferenceField>
          <FunctionField
            label="Total Outgoings"
            render={() => intlFormatter.format(totalOutgoing)}
            className={classes.nextFormGroup}
          />
          <ReferenceField
            label="House"
            source="affordability"
            reference="loans/adminui/affordability"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="housingCost" />
          </ReferenceField>
          <ReferenceField
            label="Bills"
            source="affordability"
            reference="loans/adminui/affordability"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="bills" />
          </ReferenceField>
          <ReferenceField
            label="Essentials"
            source="affordability"
            reference="loans/adminui/affordability"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="otherExpense" />
          </ReferenceField>
          <ReferenceField
            label="Installment Loans"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.regularpayments" />
          </ReferenceField>
          <ReferenceField
            label="Credit Cards"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source={`raw_response.WorkflowOutput.cardpayments`} />
          </ReferenceField>
          <FunctionField
            label="Remaining"
            render={() => intlFormatter.format(affordabilityNet)}
            className={classes.nextFormGroup}
          />
          <div />
          <ReferenceField
            label="Affordability2 Income"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingIncome" />
          </ReferenceField>
          <ReferenceField
            label="Carfinance"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.expenditure_carfinance" />
          </ReferenceField>
          <ReferenceField
            label="System"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="raw_response.WorkflowOutput.expenditure_carfinance_system" />
          </ReferenceField>
          <ReferenceField
            label="Creditcard"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.expenditure_creditcard" />
          </ReferenceField>
          <ReferenceField
            label="System"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="raw_response.WorkflowOutput.expenditure_creditcard_system" />
          </ReferenceField>
          <ReferenceField
            label="Installmentloan"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.expenditure_installmentloan" />
          </ReferenceField>
          <ReferenceField
            label="System"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="raw_response.WorkflowOutput.expenditure_installmentloan_system" />
          </ReferenceField>
          <ReferenceField
            label="Home"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.expenditure_home" />
          </ReferenceField>
          <ReferenceField
            label="System"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <BooleanField source="raw_response.WorkflowOutput.expenditure_home_system" />
          </ReferenceField>
          <ReferenceField
            label="Underwriting Expenditure"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingExpenditure" />
          </ReferenceField>
          <ReferenceField
            label="Underwriting NDI"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.underwritingNDI" />
          </ReferenceField>
          <ReferenceField
            label="CustomerMonthlyIncome"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.CustomerMonthlyIncome" />
          </ReferenceField>
          <ReferenceField
            label="CustomerDeclaredExpenditure"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.CustomerDeclaredExpenditure" />
          </ReferenceField>
          <ReferenceField
            label="CustomerDeclaredNDI"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="raw_response.WorkflowOutput.CustomerDeclaredNDI" />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Total Regular Income"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <FunctionField
              render={(record: any) =>
                intlFormatter.format(
                  (record.net_income + record.net_other_income) / 12,
                )
              }
            />
          </ReferenceField>
          <ReferenceField
            label="Monthly Salary"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <FunctionField
              render={(record: any) =>
                intlFormatter.format(record.net_income / 12)
              }
            />
          </ReferenceField>
          <ReferenceField
            label="Other Monthly Income"
            source="profile"
            reference="loans/adminui/profile"
            link="show"
            className={classes.nextFormGroup}
          >
            <FunctionField
              render={(record: any) =>
                intlFormatter.format(record.net_other_income / 12)
              }
            />
          </ReferenceField>
          <ReferenceField
            label="Transactions Salary"
            source="user_id"
            reference="loans/decision/salary"
            link="show"
            className={classes.nextFormGroup}
          >
            <FunctionField
              render={(record: any) =>
                intlFormatter.format(record.past_3_month_salary)
              }
            />
          </ReferenceField>
          <ReferenceField
            label="Transactions Salary Confidence"
            source="user_id"
            reference="loans/decision/salary"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="confidence" />
          </ReferenceField>
          <ReferenceField
            label="New Category Salary"
            source="user_id"
            reference="transactions/adminui/user/salary/cognito"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="count" />
          </ReferenceField>
          <div />
          <ReferenceField
            label="Transactions Housing Cost"
            source="user_id"
            reference="loans/decision/housing"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="cost" />
          </ReferenceField>
          <ReferenceField
            label="Transactions Housing Confidence"
            source="user_id"
            reference="loans/decision/housing"
            link="show"
            className={classes.nextFormGroup}
          >
            <NumberField source="confidence" />
          </ReferenceField>
          <div />
          <FunctionField
            label="BestSalaryGuess"
            render={() => intlFormatter.format(salaryForCalc)}
            className={classes.nextFormGroup}
          />
          <FunctionField
            label="BureauCreditCommittment"
            render={() =>
              credit_decision?.raw_response?.WorkflowOutput
                ?.BureauCreditCommitment
            }
            className={classes.nextFormGroup}
          />
          <FunctionField
            label="Bills"
            render={() => aff?.bills}
            className={classes.nextFormGroup}
          />
          <FunctionField
            label="Housing"
            render={() => aff?.housingCost}
            className={classes.nextFormGroup}
          />
          <FunctionField
            label="Other Expense"
            render={() => aff?.otherExpense}
            className={classes.nextFormGroup}
          />
          <FunctionField
            label="Fully Calculated NDI"
            render={() => intlFormatter.format(updatedNDI)}
            className={classes.nextFormGroup}
          />
          <ReferenceField
            label="Salary Transactions"
            source="user_id"
            reference="loans/decision/salary"
          >
            <ArrayField source="matched_transactions">
              <Datagrid>
                <DateField source="timestamp" />
                <NumberField source="amount" />
                <TextField source="description" />
                <TextField source="spend_merchant" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
          <ReferenceField
            label="Benefits Transactions"
            source="user_id"
            reference="transactions/decision/benefits"
          >
            <ArrayField source="transactions">
              <Datagrid>
                <DateField source="timestamp" />
                <NumberField source="amount" />
                <TextField source="description" />
                <TextField source="spend_class_1" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </Tab>
        <Tab label="bills">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            label="Cat 2 Summary"
            source="user_id"
            reference="transactions/adminui/bills"
          >
            <ArrayField source="total_cat_2">
              <Datagrid>
                <TextField source="name" />
                <NumberField label="Last Amount" source="amount" />
                <NumberField label="Monthly Estimate" source="estimated" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>

          <ReferenceField
            label="Individual Bills"
            source="user_id"
            reference="transactions/adminui/bills"
          >
            <ArrayField source="data">
              <Datagrid>
                <DateField source="timestamp" />
                <TextField source="spend_cat_2" />
                <TextField source="spend_class_1" />
                <TextField source="spend_merchant" />
                <TextField source="description" />
                <NumberField label="Last Amount" source="amount" />
                <NumberField label="Monthly Estimate" source="estimated" />
                <BooleanField source="paid" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </Tab>
        <Tab label="borrowings">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            label="Lenders"
            source="offer_review"
            reference="loans/adminui/decision"
            link="show"
          >
            <ArrayField source="raw_response.WorkflowOutput.BorrowingProducts">
              <Datagrid>
                <TextField label="Provider" source="spend_merchant" />
                <NumberField label="Rep APR" source="rep_apr" />
                <NumberField label="Most likely balance" source="balance" />
                <NumberField
                  label="Repayments"
                  source="monthly"
                  options={{ maximumFractionDigits: 0 }}
                />
                <NumberField label="Transaction Count" source="total_count" />
                <TextField label="Product" source="product" />
                <BooleanField label="Included" source="include" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
          <ReferenceField
            label="Overdrafts"
            source="user_id"
            reference="transactions/adminui/overdraft"
          >
            <ArrayField source="overdrafts">
              <Datagrid>
                <TextField label="Bank" source="calculated.provider" />
                <NumberField
                  label="Monthly Cost"
                  source="calculated.monthly_cost"
                />
                <FunctionField
                  label="Rate"
                  render={(record: any) =>
                    `${intlFormatter.format(
                      Math.round(record.calculated.rate * 10) / 10,
                    )}%`
                  }
                />
                <NumberField label="Limit" source="calculated.limit" />
                <NumberField
                  label="Current Balance"
                  source="calculated.balance"
                />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </Tab>
        <Tab label="TU">
          <ReferenceField
            label="matchLevel"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchLevel" />
          </ReferenceField>
          <ReferenceField
            label="netMonthly"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.incomeSummary.calculatedIncome.netMonthly" />
          </ReferenceField>
          <ReferenceField
            label="incomeVerificationRag"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.incomeSummary.singleViewOfIncome.incomeVerificationRag" />
          </ReferenceField>
          <ReferenceField
            label="singleViewOfIncome.confidence"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.incomeSummary.singleViewOfIncome.confidence" />
          </ReferenceField>
          <ReferenceField
            label="numberOfFinancialRelationships"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.accountHoldings.numberOfFinancialRelationships" />
          </ReferenceField>
          <ReferenceField
            label="totalNumberOfCreditCards"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.accountHoldings.totalNumberOfCreditCards" />
          </ReferenceField>
          <ReferenceField
            label="totalNumberOfCurrentAccounts"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.accountHoldings.totalNumberOfCurrentAccounts" />
          </ReferenceField>
          <ReferenceField
            label="totalNumberOfLoans"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.accountHoldings.totalNumberOfLoans" />
          </ReferenceField>
          <ReferenceField
            label="totalNumberOfMortgages"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.accountHoldings.totalNumberOfMortgages" />
          </ReferenceField>
          <ReferenceField
            label="totalDebtAmountIncludingSecured"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.incomeDebtRatio.totalDebtAmountIncludingSecured" />
          </ReferenceField>
          <ReferenceField
            label="totalPaymentToNetIncomeRatio"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.incomeDebtRatio.totalPaymentToNetIncomeRatio" />
          </ReferenceField>
          <ReferenceField
            label="totalUnsecuredDebtAmount"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.incomeDebtRatio.totalUnsecuredDebtAmount" />
          </ReferenceField>
          <ReferenceField
            label="numberOfBalanceIncreasesLast3Months"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.recentAccountActivity.numberOfBalanceIncreasesLast3Months" />
          </ReferenceField>
          <ReferenceField
            label="numberOfNewAccountsLast3Months"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.recentAccountActivity.numberOfNewAccountsLast3Months" />
          </ReferenceField>
          <ReferenceField
            label="totalBalanceIncreaseLast12Months"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.recentAccountActivity.totalBalanceIncreaseLast12Months" />
          </ReferenceField>
          <ReferenceField
            label="cardUtilisation.balanceLimitRatio"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.cardUtilisation.balanceLimitRatio" />
          </ReferenceField>
          <ReferenceField
            label="cardUtilisation.balanceLimitRatio3"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.cardUtilisation.balanceLimitRatio3" />
          </ReferenceField>
          <ReferenceField
            label="cardUtilisation.balanceLimitRatio12"
            source="credit_decision"
            reference="loans/adminui/decision"
            link="show"
          >
            <TextField source="raw_response.WorkflowOutput.Affordability.matchData.debtStress.cardUtilisation.balanceLimitRatio12" />
          </ReferenceField>
        </Tab>
        <Tab label="fraud">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            source="offer_review"
            reference="loans/adminui/decision/data"
            label="RiskyApplication"
          >
            <FunctionField
              render={(record: any) => {
                const cosmos = record.DataSourceStates.filter(
                  (r: any) => r.Name === "PhoneNumberCheck",
                )[0];
                const PhoneNumberCheck = cosmos.OutputJson
                  ? JSON.parse(cosmos.OutputJson)
                  : {};
                const ids = PhoneNumberCheck.active_applications.map(
                  (item: any) => item.id,
                );
                const data = PhoneNumberCheck.active_applications.reduce(
                  (prev: any, item: any) => {
                    prev[item.id] = item;
                    return prev;
                  },
                  {},
                );
                return (
                  <ListContext.Provider
                    value={{
                      resource: "offer_review",
                      ids,
                      data,
                      selectedIds: [],
                      currentSort: { field: null, order: null },
                    }}
                  >
                    <Datagrid rowStyle={creditReportRowStyle}>
                      <ReferenceField
                        source="id"
                        reference="loans/adminui/application"
                        basePath="loans/adminui/application"
                        link={(record: any) =>
                          `/loans/adminui/application/${record.id}/show`
                        }
                      >
                        <TextField source="id" />
                      </ReferenceField>
                      <TextField source="user" />
                      <ReferenceField
                        source="user"
                        reference="loans/adminui/user"
                        basePath="loans/adminui/user"
                      >
                        <TextField source="email" />
                      </ReferenceField>
                      <TextField source="status" />
                      <TextField source="amount" />
                      <TextField source="max_amount" />
                    </Datagrid>
                  </ListContext.Provider>
                );
              }}
            />
          </ReferenceField>
          <ReferenceField
            source="offer_review"
            reference="loans/adminui/decision/data"
            label="AccountDupesCheck"
          >
            <FunctionField
              render={(record: any) => {
                const cosmos = record.DataSourceStates.filter(
                  (r: any) => r.Name === "AccountDupesCheck",
                )[0];
                const AccountDupesCheck = cosmos.OutputJson
                  ? JSON.parse(cosmos.OutputJson)
                  : {};
                const ids = AccountDupesCheck.accounts.map(
                  (item: any) => item.id,
                );
                const data = AccountDupesCheck.accounts.reduce(
                  (prev: any, item: any) => {
                    prev[item.id] = item;
                    return prev;
                  },
                  {},
                );
                return (
                  <ListContext.Provider
                    value={{
                      resource: "offer_review",
                      ids,
                      data,
                      selectedIds: [],
                      currentSort: { field: null, order: null },
                    }}
                  >
                    <Datagrid rowStyle={creditReportRowStyle}>
                      <TextField source="id" />
                      <NumberField source="email" />
                      <NumberField source="number" />
                      <TextField source="sort_code" />
                      <TextField source="display_name" />
                      \ <DateField source="update_timestamp" />
                    </Datagrid>
                  </ListContext.Provider>
                );
              }}
            />
          </ReferenceField>
          <ReferenceField
            source="offer_review"
            reference="loans/adminui/decision/data"
            label="PhoneNumberCheck"
          >
            <FunctionField
              render={(record: any) => {
                const cosmos = record.DataSourceStates.filter(
                  (r: any) => r.Name === "PhoneNumberCheck",
                )[0];
                const PhoneNumberCheck = cosmos.OutputJson
                  ? JSON.parse(cosmos.OutputJson)
                  : {};
                console.log("pc", PhoneNumberCheck);
                const ids = PhoneNumberCheck.active_profiles.map(
                  (item: any) => item.id,
                );
                const data = PhoneNumberCheck.active_profiles.reduce(
                  (prev: any, item: any) => {
                    prev[item.id] = item;
                    return prev;
                  },
                  {},
                );
                return (
                  <ListContext.Provider
                    value={{
                      resource: "offer_review",
                      ids,
                      data,
                      selectedIds: [],
                      currentSort: { field: null, order: null },
                    }}
                  >
                    <Datagrid rowStyle={creditReportRowStyle}>
                      <TextField source="id" />
                      <NumberField source="email" />
                      <TextField source="phone" />
                      <TextField source="first_name" />
                      <TextField source="family_name" />
                      \ <DateField source="created_at" />
                      \ <DateField source="updated_at" />
                    </Datagrid>
                  </ListContext.Provider>
                );
              }}
            />
          </ReferenceField>
        </Tab>
        <Tab label="Credit">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            source="credit_decision"
            reference="loans/adminui/decision/data"
          >
            <FunctionField
              render={(record: any) => {
                const creditReport = getCosmosDataFromCreditDecision(record);

                const reportDate = parseISO(
                  creditReport?.Response.ProductResponses.BSBAndCreditReport7[0]
                    .Response?.jobdetails.searchdate,
                );
                let accounts = creditReport?.Response.ProductResponses.BSBAndCreditReport7[0].Response?.creditreport.applicant[0].accs?.map(
                  (x: any, i: number) => ({ ...x, id: i }),
                );
                if (!accounts) {
                  accounts = [];
                }
                accounts.sort(
                  (
                    a: { accdetails: { balance: number } },
                    b: { accdetails: { balance: number } },
                  ) => b.accdetails.balance - a.accdetails.balance,
                );
                const ids = accounts.map((item: any) => item.id);
                const data = accounts.reduce((prev: any, item: any) => {
                  prev[item.id] = item;
                  return prev;
                }, {});
                return (
                  <ListContext.Provider
                    value={{
                      resource: "credit_decision",
                      ids,
                      data,
                      selectedIds: [],
                      currentSort: { field: null, order: null },
                    }}
                  >
                    <Datagrid rowStyle={creditReportRowStyle}>
                      <TextField source="id" />
                      <TextField label="Type" source="accdetails.acctypecode" />
                      <NumberField source="accdetails.balance" />
                      <FunctionField
                        label="Interest (term)"
                        render={(record: any) => {
                          if (
                            record.accdetails.regpayment &&
                            record.accdetails.repayperiod &&
                            record.accdetails.openbalance
                          ) {
                            const calc = new Calculator();
                            calc.add(
                              SeriesAdvance.builder()
                                .setLabel("OpenBalance")
                                .setAmount(record.accdetails.openbalance)
                                .build(),
                            );
                            calc.add(
                              SeriesPayment.builder()
                                .setNumberOf(record.accdetails.repayperiod)
                                .setLabel("Instalment")
                                .setAmount(record.accdetails.regpayment)
                                .setMode(Mode.Arrear)
                                .build(),
                            );
                            try {
                              const rateDecimal = calc.solveRate(
                                new EU200848EC(),
                              );
                              return `${Math.round(rateDecimal * 1000) / 10}%`;
                            } catch (e) {
                              console.log(
                                "Unable to solve",
                                record.accdetails.regpayment,
                                record.accdetails.repayperiod,
                                record.accdetails.openbalance,
                              );
                            }
                          }
                          return "-";
                        }}
                      />
                      <FunctionField
                        label="Interest (last month)"
                        render={(record: any) => {
                          const aboveZeroMonths = record.acchistory.filter(
                            (a: { bal: number }) => a.bal > 0,
                          );
                          if (
                            aboveZeroMonths.length > 1 &&
                            record.accdetails.regpayment
                          ) {
                            const sortedHistory = aboveZeroMonths.sort(
                              (a: { m: string }, b: { m: string }) =>
                                a.m > b.m ? -1 : 1,
                            );
                            const latest = sortedHistory[0];
                            const penultimate = sortedHistory[1];
                            const monthsGap = differenceInMonths(
                              parse(latest.m, "yyyy-MM", new Date()),
                              parse(penultimate.m, "yyyy-MM", new Date()),
                            );
                            const principalChange =
                              penultimate.bal - latest.bal;
                            const totalPaid =
                              record.accdetails.regpayment * monthsGap;
                            const interestPaid = totalPaid - principalChange;
                            const interest =
                              ((interestPaid / penultimate.bal) * 12) /
                              monthsGap;
                            console.log(
                              record.id,
                              penultimate.bal,
                              latest.bal,
                              totalPaid,
                              interestPaid,
                            );
                            return `${Math.round(interest * 1000) / 10}%`;
                          }
                          return "-";
                        }}
                      />
                      <NumberField label="Limit" source="accdetails.limit" />
                      <FunctionField
                        label="Last Status"
                        render={(record: any) => {
                          const sortedHistory = record.acchistory.sort(
                            (a: { m: string }, b: { m: string }) =>
                              a.m > b.m ? -1 : 1,
                          );
                          const latest = sortedHistory[0];
                          return `${latest.acc} ${latest.m}`;
                        }}
                      />
                      <FunctionField
                        label="Payment Freeze"
                        render={(record: any) => {
                          const sortedHistory = record.acchistory.sort(
                            (a: { m: string }, b: { m: string }) =>
                              a.m > b.m ? -1 : 1,
                          );
                          const latest = sortedHistory[0];
                          let freeze = false;
                          if (
                            latest.bal &&
                            !["CA", "CC", "MO"].includes(
                              record.accdetails.acctypecode,
                            )
                          ) {
                            if (
                              differenceInMonths(
                                parse(latest.m, "yyyy-MM", new Date()),
                                reportDate,
                              ) < 3
                            ) {
                              for (
                                let i = 1;
                                i < Math.min(sortedHistory.length, 3);
                                i += 1
                              ) {
                                const monthsGap = differenceInMonths(
                                  parse(latest.m, "yyyy-MM", new Date()),
                                  parse(
                                    sortedHistory[i].m,
                                    "yyyy-MM",
                                    new Date(),
                                  ),
                                );
                                if (monthsGap > 3) {
                                  break;
                                }
                                if (sortedHistory[i].bal > latest.bal) {
                                  freeze = false;
                                } else {
                                  freeze = true;
                                  break;
                                }
                              }
                            }
                          }
                          return freeze ? "true" : "";
                        }}
                      />
                      <FunctionField
                        label="Reg Pay"
                        render={(record: any) => {
                          if (
                            ["CC", "MO"].includes(
                              record.accdetails.acctypecode,
                            ) &&
                            !record.accdetails.regpayment
                          ) {
                            return record.accdetails.balance
                              ? Math.round(record.accdetails.balance * 0.03)
                              : "";
                          }
                          return record.accdetails.regpayment;
                        }}
                      />
                      <NumberField
                        label="Reg Period"
                        source="accdetails.repayperiod"
                      />
                      <NumberField
                        label="Open Bal"
                        source="accdetails.openbalance"
                      />
                      <TextField label="Joint" source="accdetails.joint" />
                      <TextField
                        label="Name"
                        source="accholderdetails.name"
                      />
                      <DateField
                        label="Date of Birth"
                        source="accholderdetails.dob"
                      />
                      <TextField
                        label="Current Address"
                        source="accholderdetails.address.current"
                      />
                      <TextField
                        label="Address"
                        source="accholderdetails.address.Value"
                      />
                      <TextField source="default" />
                      <TextField source="delinquent" />
                      <DateField
                        label="Start Date"
                        source="accholderdetails.startdate"
                      />
                      <NumberField
                        label="Lump Payment"
                        source="accdetails.lumppayment"
                      />
                    </Datagrid>
                  </ListContext.Provider>
                );
              }}
            />
          </ReferenceField>
        </Tab>
        <Tab label="Graph">
          <ReferenceField
            label="Historic Balances"
            source="credit_decision"
            reference="loans/adminui/decision/data"
            link={false}
          >
            <FunctionField
              render={(record: any) => {
                const creditReport = getCosmosDataFromCreditDecision(record);
                const workflowOutput = record.WorkflowOutputJson
                  ? JSON.parse(record.WorkflowOutputJson)
                  : {};
                const histFit = workflowOutput.histFit
                  ? workflowOutput.histFit
                  : [[], []];
                const histBal = workflowOutput.histBal
                  ? workflowOutput.histBal
                  : [];
                const fullFitLine: any[] = [];
                for (let i = 0; i < histFit[0].length; i += 1) {
                  if (histBal[histFit[0][i]].m !== "") {
                    fullFitLine.push({
                      x: histBal[histFit[0][i]].m,
                      y: histFit[1][i],
                    });
                  }
                }
                let accounts = creditReport?.Response.ProductResponses.BSBAndCreditReport7[0].Response?.creditreport.applicant[0].accs?.map(
                  (x: any, i: number) => ({ ...x, id: i }),
                );
                if (!accounts) {
                  accounts = [];
                }
                accounts.sort(
                  (
                    a: { accdetails: { balance: number } },
                    b: { accdetails: { balance: number } },
                  ) => b.accdetails.balance - a.accdetails.balance,
                );
                // x values are the month
                // y values are the balance
                const accountData: any = {};
                const accSet: Set<string> = new Set();
                const accTypeSet: Set<string> = new Set();
                accounts.forEach((account: any) => {
                  accTypeSet.add(account.accdetails.acctypecode);
                  if (
                    accountTypes.length &&
                    accountTypes.find(
                      (a: any) =>
                        account.accdetails.acctypecode === a.title &&
                        a.selected,
                    )
                  ) {
                    account.acchistory.forEach(
                      (hist: {
                        m: string;
                        bal: number;
                        limit: number;
                        limitSpecified: boolean;
                        acc: string;
                        pay: string;
                      }) => {
                        accSet.add(hist.acc);
                        if (
                          accountData[hist.m] &&
                          accountData[hist.m][hist.acc]
                        ) {
                          accountData[hist.m][hist.acc] += hist.bal;
                        } else if (accountData[hist.m]) {
                          accountData[hist.m][hist.acc] = hist.bal;
                        } else {
                          accountData[hist.m] = {};
                          accountData[hist.m][hist.acc] = hist.bal;
                        }
                      },
                    );
                  }
                });
                if (accountTypes.length !== Array.from(accTypeSet).length) {
                  setAccountTypes(
                    Array.from(accTypeSet).map((s) => ({
                      title: s,
                      selected: true,
                    })),
                  );
                }
                const allData = Object.keys(accountData)
                  .map((key: string) => ({ x: key, data: accountData[key] }))
                  .sort((a, b) => (a.x > b.x ? 1 : -1));
                const allGraphArray: any = [];
                accSet.forEach((acc: string) => {
                  const graph: Array<{
                    x: string;
                    y: number;
                    label: string;
                  }> = [];
                  allData.forEach((d: any) => {
                    graph.push({
                      x: d.x,
                      y: d.data[acc] ? d.data[acc] : 0,
                      label: acc,
                    });
                  });
                  allGraphArray.push(graph);
                });
                const fitLine: any[] = [];
                Object.keys(accountData).forEach((key: string) => {
                  const fl = fullFitLine.find(
                    (x: { x: string }) => x.x === key,
                  );
                  if (fl) {
                    fitLine.push(fl);
                  }
                });
                return (
                  <>
                    <XYPlot
                      height={300}
                      width={900}
                      stackBy="y"
                      xType="ordinal"
                    >
                      <VerticalGridLines />
                      <HorizontalGridLines />
                      <XAxis
                        tickFormat={(v: string) => {
                          if (v.search("-12") !== -1) {
                            return v;
                          }
                          return "";
                        }}
                      />
                      <YAxis tickFormat={(v: number) => `${v / 1000}k`} />
                      {allGraphArray.map((data: any) => (
                        <VerticalBarSeries
                          key={data[0].label}
                          data={data}
                          barWidth={0.9}
                        />
                      ))}
                      <LineSeries color="red" data={fitLine} />
                    </XYPlot>
                    <DiscreteColorLegend
                      orientation="horizontal"
                      items={allGraphArray.map((series: any) => {
                        // console.log(series.props.colour+"");
                        return {
                          title: `${series[0].label} ${
                            AccountStatusMappings[series[0].label]
                          }`,
                          strokeWidth: 15,
                        };
                      })}
                    />
                  </>
                );
              }}
            />
          </ReferenceField>
          <FormControl component="fieldset">
            <FormLabel component="legend">Show Account Types</FormLabel>
            <FormGroup>
              {Array.from(accountTypes).map((series: any) => (
                <FormControlLabel
                  key={series.title}
                  control={
                    <Checkbox
                      checked={series.selected}
                      onChange={(event) => {
                        console.log(
                          "event",
                          event.target.name,
                          event.target.checked,
                        );
                        setAccountTypes(
                          accountTypes.map((a) =>
                            a.title === event.target.name
                              ? {
                                  title: a.title,
                                  selected: event.target.checked,
                                }
                              : a,
                          ),
                        );
                      }}
                      name={series.title}
                    />
                  }
                  label={`${series.title} ${AccountTypeMappings[series.title]}`}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Tab>
        {/*
        <Tab label="underwriting_categories">
          <ReferenceField source="user" reference="loans/adminui/user">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField source="user_id" reference="transactions/underwriting/accounts/categories">
            <ArrayField source="list">
              <Datagrid>
                <TextField label="Category" source="underwriting_category" />
                <TextField label="Type" source="underwriting_subcategory" />
                <NumberField label="Total Spent" source="total_price" />
                <NumberField label="Transactions" source="total_count" />
              </Datagrid>
            </ArrayField>
          </ReferenceField>
        </Tab>
          */}
      </TabbedShowLayout>
    </Show>
  );
};

export default ApplicationShow;
