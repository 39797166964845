import * as React from "react";
import { useCallback, FunctionComponent } from "react";
import PropTypes from "prop-types";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useNotify, useListContext, fetchRelatedRecords } from "ra-core";
import { Button, withDataProvider } from "react-admin";

const ExportButton: FunctionComponent<ExportButtonProps> = (props) => {
  const {
    maxResults = 1000,
    onClick,
    label = "ra.action.export",
    icon = defaultIcon,
    exporter: customExporter,
    dataProvider,
    ...rest
  } = props;
  const {
    resource,
    currentSort,
    filterValues,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const exporter = customExporter || exporterFromContext;
  const notify = useNotify();
  const fetchPage = useCallback(
    (resource: any, page: number, allData: any[]) => {
      return dataProvider("GET", resource, {
        sort: currentSort,
        filter: filterValues,
        pagination: { page: page, perPage: maxResults },
      }).then((data: any) => {
        console.log(data);
        allData.push(...data.data.results);
        notify(
          `Fetched ${page * maxResults} records of ${data.data.count}`,
          "info",
        );
        if (data.data.next) {
          return fetchPage(resource, page + 1, allData);
        }
        return allData;
      });
    },
    [maxResults, currentSort, dataProvider, notify, filterValues],
  );
  const handleClick = useCallback(
    (event) => {
      const allData: any[] = [];
      dataProvider &&
        fetchPage(resource, 1, allData)
          .then(
            (data: any) =>
              exporter &&
              exporter(
                data,
                fetchRelatedRecords(dataProvider),
                dataProvider,
                resource,
              ),
          )
          .catch((error: any) => {
            console.error(error);
            notify("ra.notification.http_error", "warning");
          });
      if (typeof onClick === "function") {
        onClick(event);
      }
    },
    [dataProvider, notify, onClick, resource, fetchPage, exporter],
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
      disabled={total === 0}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
  basePath,
  resource,
  ...rest
}: Omit<ExportButtonProps, "sort" | "maxResults" | "label" | "exporter">) =>
  rest;

interface Props {
  dataProvider?: any;
  basePath?: string;
  exporter?: any;
  filterValues?: any;
  icon?: JSX.Element;
  label?: string;
  maxResults?: number;
  onClick?: (e: Event) => void;
  resource?: string;
}

export type ExportButtonProps = Props;

ExportButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filterValues: PropTypes.object,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  resource: PropTypes.string,
  icon: PropTypes.element,
};

export default withDataProvider(ExportButton);
