import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Toolbar,
} from "react-admin";

type Props = {};

const ShowToolbar = (props: any) => <Toolbar {...props}></Toolbar>;

const accounttransactionShow = (props: Props) => (
  <Show {...props}>
    <SimpleShowLayout toolbar={<ShowToolbar />}>
      <TextField source="id" />
      <TextField source="merchant_display" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <TextField source="amount" />
      <TextField source="currency" />
      <DateField source="timestamp" />
      <TextField source="transaction_type" />
      <TextField source="transaction_category" />
      <TextField source="description" />
      <TextField source="meta" />
      <TextField source="transaction_classification" />
      <TextField source="merchant_name" />
      <TextField source="running_balance" />
      <TextField source="running_balance_currency" />
      <TextField source="estimated_balance" />
      <TextField source="balance" />
      <TextField source="underwriting_category" />
      <TextField source="underwriting_subcategory" />
      <TextField source="spend_cat_1" />
      <TextField source="spend_cat_2" />
      <TextField source="spend_class_1" />
      <TextField source="spend_merchant" />
    </SimpleShowLayout>
  </Show>
);

export default accounttransactionShow;
